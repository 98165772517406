import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import { BofSalesArgumentDocument } from '../../../generated/backend/graphql';

type Props = {
  homeId: string;
};

function SalesArgument({ homeId }: Props) {
  const L = locale.backoffice;

  const { data, loading } = useQuery(BofSalesArgumentDocument, {
    skip: !homeId,
    ssr: true,
    variables: {
      homeId: `${homeId}`,
    },
  });

  return (
    <Box>
      <h2>{L.salesArguments.title}</h2>
      {loading && <p>{L.loading}</p>}
      {data?.constructArgument?.argument && (
        <pre style={{ whiteSpace: 'pre-wrap' }}>
          {data?.constructArgument?.argument}
        </pre>
      )}
    </Box>
  );
}

export default SalesArgument;
