import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Card,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  TextField,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Stack,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { sv as locale } from '@norban/locale';
import { compareDesc, isToday, isYesterday } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';

import { activityLogMap } from '../../../../components/activityLogMap';
import QueryError from '../../../../components/QueryError';
import {
  BofHomeActivityLogViewCreateLogDocument,
  BofHomeActivityLogViewDeleteLogDocument,
  BofHomeActivityLogViewLogsDocument,
  EventType,
  Role,
} from '../../../../generated/backend/graphql';
import useSession from '../../../../hooks/useSession';

const L = locale.backoffice;

type Entry = {
  id: string;
  timestamp: Date;
  message: string;
  type: string;
  originator?: {
    id: string;
    name: string;
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    overflowX: 'auto',
  },
  dayDividerRow: {
    background: grey.A200,
  },
  dayDividerCell: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  message: {
    minWidth: 300,
    wordBreak: 'break-word',
  },
}));

const ActivityLogRow = ({
  entry,
  deleteLogEntry,
}: {
  entry: Entry;
  deleteLogEntry: (args: { variables: { id: string } }) => void;
}) => {
  const classes = useStyles();

  return (
    <TableRow key={entry.id}>
      <TableCell>
        {
          /* hours and minutes only */
          entry.timestamp.toLocaleTimeString('sv-SE', {
            hour: '2-digit',
            minute: '2-digit',
          })
        }
      </TableCell>
      <TableCell>{activityLogMap[entry.type]}</TableCell>
      <TableCell className={classes.message}>{entry.message}</TableCell>
      <TableCell>{entry.originator && entry.originator.name}</TableCell>
      <TableCell>
        {entry.type === EventType.CrmCustomEntry ? (
          <IconButton
            aria-label="delete"
            onClick={evt => {
              evt.stopPropagation();
              deleteLogEntry({
                variables: { id: entry.id },
              });
            }}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

const DayDividerRow = ({ timestamp }: { timestamp: Date }) => {
  const classes = useStyles();

  let dateSuffix = '';

  if (isToday(timestamp)) {
    dateSuffix = '- Idag';
  } else if (isYesterday(timestamp)) {
    dateSuffix = '- Igår';
  }

  return (
    <TableRow key={timestamp.getTime()} className={classes.dayDividerRow}>
      <TableCell colSpan={6} className={classes.dayDividerCell}>
        {`${timestamp.toLocaleDateString('sv-SE')} ${dateSuffix}`}
      </TableCell>
    </TableRow>
  );
};

const ActivityLogTable = ({
  entries,
  loading,
  deleteLogEntry,
}: {
  entries: Entry[];
  loading: boolean;
  deleteLogEntry: (args: { variables: { id: string } }) => void;
}) => {
  const classes = useStyles();

  const dateGroupedEntries = useMemo(
    () =>
      Object.entries(
        // Group entries by date
        entries.reduce<Record<string, Entry[]>>((acc, entry) => {
          const dateOnlyString = entry.timestamp.toDateString();

          return {
            ...acc,
            [dateOnlyString]: [...(acc[dateOnlyString] ?? []), entry],
          };
        }, {}),
      )
        // For each grouped date, sort entries by timestamp and return an object with date and entries
        .flatMap(([, entries]) => {
          const date = entries[0]?.timestamp;
          if (!date) {
            return [];
          }

          return [
            {
              date,
              entries: entries.sort(({ timestamp: a }, { timestamp: b }) =>
                compareDesc(a, b),
              ),
            },
          ];
        })
        // Sort groups by date
        .sort(({ date: a }, { date: b }) => compareDesc(a, b)),
    [entries],
  );

  if (loading) {
    return <p>{L.loading}</p>;
  }

  return (
    <Paper className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{L.date}</TableCell>
            <TableCell>{L.activityLogView.type}</TableCell>
            <TableCell>{L.activityLogView.event}</TableCell>
            <TableCell>{L.activityLogView.createdBy}</TableCell>
            <TableCell>{L.delete}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dateGroupedEntries.flatMap(({ date, entries }) => [
            <DayDividerRow
              key={date.toISOString()}
              timestamp={new Date(date)}
            />,
            ...entries.map(entry => (
              <ActivityLogRow
                key={entry.id}
                entry={entry}
                deleteLogEntry={deleteLogEntry}
              />
            )),
          ])}
        </TableBody>
      </Table>
    </Paper>
  );
};

const HomeActivityLogView = ({ homeId }: { homeId: string }) => {
  const [message, setMessage] = useState('');
  const [timestamp, setTimestamp] = useState<Date | null>(null);
  const [logExpanded, setLogExpanded] = useState(false);
  const { userId: originatorId } = useSession();

  const { loading, error, data, refetch } = useQuery(
    BofHomeActivityLogViewLogsDocument,
    {
      variables: {
        homeId,
      },
    },
  );

  const [createLogEntry] = useMutation(
    BofHomeActivityLogViewCreateLogDocument,
    {
      onCompleted: () => refetch(),
      variables: {
        input: {
          homeId,
          timestamp:
            timestamp && !isNaN(timestamp.getTime())
              ? timestamp?.toISOString()
              : new Date().toISOString(),
          message,
          type: EventType.CrmCustomEntry,
          originatorId: `${originatorId}`,
        },
      },
    },
  );

  const [deleteLogEntry] = useMutation(
    BofHomeActivityLogViewDeleteLogDocument,
    {
      onCompleted: () => refetch(),
    },
  );

  const addLogEntry = useCallback(() => {
    if (message.length > 0) {
      createLogEntry();
      setMessage('');
      setTimestamp(null);
    }
  }, [createLogEntry, message]);

  if (error) {
    return <QueryError error={error} data={data} />;
  }

  const userEntries = data?.logEntries ?? [];

  const entries: Entry[] = [
    ...userEntries.map(entry => {
      let name = '';
      if (entry.originator?.role === Role.Admin) {
        name = entry.originator?.name ?? '';
      } else if (entry.originator?.id) {
        name = `USER:${entry.originator?.id}`;
      } else {
        name = '';
      }

      return {
        ...entry,
        timestamp: new Date(entry.timestamp),
        originator: {
          id: entry.originator?.id ?? '',
          name,
        },
      };
    }),
  ];

  return (
    <Card>
      <CardHeader
        title={
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            justifyContent="flex-start"
          >
            <Box>Logg</Box>
            <Box ml={0}>
              <IconButton
                onClick={() => setLogExpanded(!logExpanded)}
                size="large"
                style={{ marginLeft: 'auto' }}
              >
                <ExpandMoreIcon
                  style={{
                    transform: logExpanded ? 'rotate(180deg)' : undefined,
                  }}
                />
              </IconButton>
            </Box>
          </Stack>
        }
      />
      <Box overflow="hidden">
        <Collapse in={logExpanded} timeout="auto" unmountOnExit>
          <Box margin={2}>
            <Grid alignItems="center" container spacing={2}>
              <Grid item xs={12} md flexGrow={1}>
                <TextField
                  fullWidth
                  label={L.logView.entry}
                  value={message}
                  type="action"
                  name="action"
                  onChange={evt => setMessage(evt.target.value)}
                />
              </Grid>
              <Grid item xs={12} md="auto">
                <DateTimePicker
                  ampm={false}
                  format="yyyy-MM-dd HH:mm"
                  label={L.datetime}
                  value={timestamp}
                  onChange={value => setTimestamp(value)}
                />
              </Grid>
              <Grid item xs={12} md="auto">
                <Button
                  color="secondary"
                  size="small"
                  startIcon={<AddIcon />}
                  variant="contained"
                  onClick={addLogEntry}
                >
                  {L.add}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
        <ActivityLogTable
          entries={entries}
          loading={loading}
          deleteLogEntry={deleteLogEntry}
        />
      </Box>
    </Card>
  );
};

export default HomeActivityLogView;
