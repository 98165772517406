import ErrorIcon from '@mui/icons-material/Error';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Box, Button, SnackbarContent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/system';
import React, { useCallback, useEffect, useState } from 'react';

import useSession from '../hooks/useSession';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorSnackbar: {
    backgroundColor: theme.palette.error.dark,
  },
  errorIcon: {
    marginRight: theme.spacing(1),
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const LogIn = () => {
  const { refreshJwt, initialLoadDone } = useSession();
  const [email, setEmail] = useState('');
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [waitingForEmail, setWaitingForEmail] = useState(false);
  const [emailChallengeAntiPhishingCode, setAntiPhishingCode] = useState('');

  useEffect(() => {
    if (!waitingForEmail) {
      return () => {};
    }

    const interval = setInterval(async () => {
      // Refresh the JWT periodically until the user has clicked the link
      await refreshJwt();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [refreshJwt, waitingForEmail]);

  const { loginEmail } = useSession();

  const handleSubmit = useCallback(
    async (evt: React.FormEvent<HTMLFormElement>) => {
      evt.preventDefault();

      setLoading(true);
      try {
        const result = await loginEmail({
          email,
          challengeResponseRedirectUrl: window.location.href,
        });

        const loginEmailResult = result.mutationResult?.loginEmailResult;
        if (loginEmailResult) {
          setAntiPhishingCode(
            loginEmailResult.emailChallengeAntiPhishingCode ?? '',
          );
          setWaitingForEmail(true);
        }
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [email, loginEmail],
  );

  const errorMessage = error && (
    <SnackbarContent
      className={classes.errorSnackbar}
      message={
        <span id="client-snackbar" className={classes.errorMessage}>
          <ErrorIcon className={classes.errorIcon} />
          Failed to log in
        </span>
      }
    />
  );

  if (!initialLoadDone) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Laddar session
          </Typography>
          <Box mt={2}>
            <CircularProgress />
          </Box>
        </div>
      </Container>
    );
  }

  if (waitingForEmail) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            textAlign="center"
            marginBottom="1em"
          >
            Kontrollera din e-post. Du kan bli ombedd att ange följande kod:
          </Typography>
          <Typography component="h1" variant="h3">
            {emailChallengeAntiPhishingCode}
          </Typography>
        </div>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log In
        </Typography>

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            disabled={loading}
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={evt => setEmail(evt.target.value)}
          />
          <Button
            type="submit"
            disabled={loading}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Logga in
          </Button>
        </form>
        {loading && <CircularProgress />}
        {errorMessage}
      </div>
    </Container>
  );
};

export default LogIn;
