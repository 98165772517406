import { Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Stack } from '@mui/system';
import React, { ReactNode } from 'react';

import LinkButton from './LinkButton';

export type PageHeaderButton = {
  text: string;
  icon?: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  outline?: string;
};

export type PageHeaderLink = {
  link: string;
  copyableLink?: string;
  content: string;
};

type Props = {
  title: ReactNode;
  subtitle?: ReactNode;
  overline?: string;
  links?: PageHeaderLink[];
  buttons?: PageHeaderButton[];
};

const useStyles = makeStyles(() => ({
  button: {
    userSelect: 'text',
  },
}));

const PageHeader = ({
  overline = undefined,
  title,
  subtitle = undefined,
  links = undefined,
  buttons = undefined,
}: Props) => {
  const classes = useStyles();

  return (
    <Grid container rowGap={4} pb={2}>
      {/* Overline */}
      {overline ? (
        <Grid item xs={12}>
          <Typography variant="overline" display="block" gutterBottom>
            {overline}
          </Typography>
        </Grid>
      ) : null}
      {/* Title, subtitle and links */}
      <Grid item xs={12} md>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
            {title && (
              <Typography
                fontWeight="bold"
                variant="h5"
                display="block"
                gutterBottom
              >
                {title}
              </Typography>
            )}
            {links?.map((l, index) => (
              <LinkButton key={index} to={l.link} content={l.content} />
            ))}
          </Stack>
          {subtitle && (
            <Typography variant="body1" gutterBottom component="div">
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Grid>
      {/* Buttons */}
      {buttons && (
        <Grid item xs={12} md="auto">
          <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap>
            {buttons?.map((btn, index) => (
              <Button
                className={classes.button}
                key={index}
                disabled={btn.disabled}
                variant={btn.variant ?? 'contained'}
                color="secondary"
                size="medium"
                startIcon={btn.icon}
                onClick={btn.onClick}
                sx={{ outline: btn?.outline ?? 'inherit' }}
              >
                {btn.text}
              </Button>
            ))}
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default PageHeader;
