import { Grid } from '@mui/material';
import React from 'react';

import UserAutocomplete from '../../../components/UserAutocomplete';

const LeadTemplatesAssignedAgentPicker = ({
  selectedAgentId,
  onSelectAgentId,
}: {
  selectedAgentId: string | null;
  onSelectAgentId: (id: string | null) => void;
}) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={9} lg={6}>
        <UserAutocomplete
          defaultLimit={300}
          fullWidth
          limitToUserRoles={['admin']}
          onSelectedUserIdChanged={id => onSelectAgentId(id)}
          selectedUserId={selectedAgentId}
          sortAlphabetically
          size="small"
          label="Avsändare"
        />
      </Grid>
    </Grid>
  );
};

export default LeadTemplatesAssignedAgentPicker;
