import { isDisclosed } from './state';

// Duplicated from GraphQL generated types
enum HomeState {
  Hidden = 'HIDDEN',
  Invalid = 'INVALID',
  None = 'NONE',
  OpenMarket = 'OPEN_MARKET',
  Premarket = 'PREMARKET',
  PremarketLight = 'PREMARKET_LIGHT',
  Sold = 'SOLD',
  Withdrawn = 'WITHDRAWN',
}

export enum TenureType {
  /** Swedish: Ägarlägenhet */
  Condominium = 'CONDOMINIUM',
  /** Swedish: Andelsrätt */
  Coownership = 'COOWNERSHIP',
  /** Swedish: Fritidshus */
  Cottage = 'COTTAGE',
  /** Swedish: Friköpt */
  Freehold = 'FREEHOLD',
  /**
   * This is a special state, before we've determined the specific tenure
   * pertaining a house (freehold or leasehold)
   *
   * Swedish: Hus
   */
  GenericHouse = 'GENERIC_HOUSE',
  /** Swedish: Bostadsrätt */
  HousingCooperative = 'HOUSING_COOPERATIVE',
  /** Swedish: Tomträtt */
  Leasehold = 'LEASEHOLD',
  /** Swedish: Projekt (för nyproduktion) */
  Project = 'PROJECT',
  /** Swedish: Andel i bostadsförening */
  ShareInHousingAssociation = 'SHARE_IN_HOUSING_ASSOCIATION',
}

export function formatHomeArea(
  adHideArea: boolean,
  areaName?: string | null,
  city?: string | null,
): string {
  if (!adHideArea) {
    // Special case, area and city are the same, print it once
    if (areaName && city && areaName === city) {
      return areaName;
    }

    return `${areaName || ''}${areaName && city ? ', ' : ''}${city || ''}`;
  }

  return city || '';
}

export function formatUndisclosedName(
  undisclosedAddressName?: string,
  areaName?: string,
): string {
  return undisclosedAddressName || areaName || '';
}

export function formatAddress(
  state: HomeState,
  streetAddress?: string | null,
  undisclosedAddressName?: string | null,
  areaName?: string | null,
): string {
  return isDisclosed(state)
    ? streetAddress || ''
    : formatUndisclosedName(
        undisclosedAddressName ?? undefined,
        areaName ?? undefined,
      );
}

export function formatHomeTitle(
  state: HomeState,
  tenure?: TenureType | null,
  newConstructionProjectName?: string | null,
  streetAddress?: string | null,
  undisclosedAddressName?: string | null,
  areaName?: string | null,
): string {
  if (tenure === TenureType.Project) {
    return newConstructionProjectName || '';
  }
  if (state !== HomeState.PremarketLight) {
    return streetAddress || '';
  }

  return formatUndisclosedName(
    undisclosedAddressName ?? undefined,
    areaName ?? undefined,
  );
}
