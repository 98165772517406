import { CssBaseline } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/system';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';

import useSession from '../hooks/useSession';
import Agent from '../scenes/Agent';
import AgentsDash from '../scenes/AgentsDash/AgentsDash';
import AllAgreementContracts from '../scenes/AllAgreementContracts/AllAgreementContracts';
import AreaNewView from '../scenes/AreaNewView';
import Areas from '../scenes/Areas';
import AreaView from '../scenes/AreaView';
import Chats from '../scenes/Chats/Chats';
import ChatById from '../scenes/Chats/components/ChatById';
import Events from '../scenes/Events';
import Followers from '../scenes/Followers';
import Home from '../scenes/Home';
import HomeMessages from '../scenes/HomeMessages/HomeMessages';
import Homes from '../scenes/Homes';
import LeadTemplates from '../scenes/LeadTemplates';
import Offices from '../scenes/Offices';
import OpenMarketRedirects from '../scenes/OpenMarketRedirects';
import ScheduledEmail from '../scenes/ScheduledEmail';
import Stats from '../scenes/Stats';
import TemporaryHousing from '../scenes/TemporaryHousing';
import User from '../scenes/User';
import UserImages from '../scenes/UserImages/UserImages';
import UserLabel from '../scenes/UserLabel';
import UserList from '../scenes/UserList';

import LogIn from './LogIn';
import TopDrawer from './TopDrawer';
import TopNavigation from './TopNavigation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    width: 'calc(100% - 240px)',
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toolbar: (theme.mixins as any).toolbar,
}));

const BaseLayout = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();

  const { userId, role } = useSession();

  if (!userId || role !== 'ADMIN') {
    return <LogIn />;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Backoffice</title>
      </Helmet>
      <CssBaseline />
      <TopNavigation onToggleMenu={() => setDrawerOpen(!drawerOpen)} />
      <TopDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route path="/areas">
            <Helmet>
              <title>Områden</title>
            </Helmet>
            <Areas />
          </Route>

          <Route path="/area/:id">
            <Helmet>
              <title>Område</title>
            </Helmet>
            <AreaView />
          </Route>

          <Route path="/area-new">
            <Helmet>
              <title>Område - nytt</title>
            </Helmet>
            <AreaNewView />
          </Route>

          <Route path="/users" exact>
            <Helmet>
              <title>Användare</title>
            </Helmet>
            <UserList />
          </Route>

          <Route path="/homes" exact>
            <Helmet>
              <title>Bostäder</title>
            </Helmet>
            <Homes />
          </Route>

          <Route path="/followers" exact>
            <Helmet>
              <title>Följare</title>
            </Helmet>
            <Followers />
          </Route>

          <Route
            path="/homes/:homeId"
            render={({
              match: {
                params: { homeId },
              },
            }) => (
              <>
                <Helmet>
                  <title>{`Bostad ${homeId}`}</title>
                </Helmet>

                <Home homeId={homeId} />
              </>
            )}
          />

          <Route
            path="/users/:userId"
            render={({
              match: {
                params: { userId },
              },
            }) => (
              <>
                <Helmet>
                  <title>{`Användare ${userId}`}</title>
                </Helmet>
                <User userId={userId} />
              </>
            )}
          />

          <Route path="/events">
            <Helmet>
              <title>Händelser</title>
            </Helmet>
            <Events />
          </Route>

          <Route path="/temporary-housing">
            <Helmet>
              <title>Mellanboende</title>
            </Helmet>
            <TemporaryHousing />
          </Route>

          <Route path="/stats">
            <Helmet>
              <title>Statistik</title>
            </Helmet>
            <Stats />
          </Route>

          <Route path="/user-label">
            <Helmet>
              <title>Användarlabels</title>
            </Helmet>
            <UserLabel />
          </Route>

          <Route path="/open-market-redirects">
            <Helmet>
              <title>ÖM redirects</title>
            </Helmet>
            <OpenMarketRedirects />
          </Route>

          <Route path="/offices">
            <Helmet>
              <title>Kontor</title>
            </Helmet>
            <Offices />
          </Route>

          <Route path="/leadTemplates">
            <Helmet>
              <title>Bearbetningsmallar</title>
            </Helmet>
            <LeadTemplates />
          </Route>

          <Route path="/scheduled-mail">
            <Helmet>
              <title>Mejlutskick</title>
            </Helmet>
            <ScheduledEmail />
          </Route>

          <Route path="/chats" exact>
            <Helmet>
              <title>Chattar</title>
            </Helmet>
            <Chats />
          </Route>

          <Route path="/chats/:id">
            <Helmet>
              <title>Chatt</title>
            </Helmet>

            <ChatById />
          </Route>

          <Route path="/home-messages">
            <Helmet>
              <title>Hälsningar</title>
            </Helmet>

            <HomeMessages />
          </Route>

          <Route path="/agreement-contracts">
            <Helmet>
              <title>Uppdragsavtal</title>
            </Helmet>

            <AllAgreementContracts />
          </Route>

          <Route path="/agents/:id">
            <Helmet>
              <title>Mäklare</title>
            </Helmet>

            <Agent />
          </Route>

          <Route path="/agents-dash">
            <Helmet>
              <title>Översikt</title>
            </Helmet>

            <AgentsDash />
          </Route>

          <Route path="/user-images">
            <Helmet>
              <title>Bilder</title>
            </Helmet>

            <UserImages />
          </Route>

          <Route>
            <Redirect to="/agents-dash" />
          </Route>
        </Switch>
      </main>
    </div>
  );
};

export default BaseLayout;
