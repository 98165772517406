import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Paper,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useState } from 'react';

import {
  BofCreateLeadTemplateDocument,
  BofUpdateLeadTemplateDocument,
  BofDeleteLeadTemplateDocument,
  BofLeadTemplatesDocument,
} from '../../generated/backend/graphql';

type Row = {
  id: string;
  name: string;
  message: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

const LeadTemplates = () => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [mutateError, setMutateError] = useState('');
  const [id, setId] = useState<number | undefined>(undefined);

  const { data, refetch, error } = useQuery(BofLeadTemplatesDocument);
  const [createLeadTemplate] = useMutation(BofCreateLeadTemplateDocument, {
    onCompleted: () => refetch(),
  });
  const [updateLeadTemplate] = useMutation(BofUpdateLeadTemplateDocument, {
    onCompleted: () => refetch(),
  });
  const [deleteLeadTemplate] = useMutation(BofDeleteLeadTemplateDocument, {
    onCompleted: () => refetch(),
  });

  const closeDialog = () => {
    setName('');
    setSubject('');
    setMessage('');
    setDialogOpen(false);
  };

  const lts = data?.leadTemplates ?? [];
  const rows = error ? [] : lts;

  const columns: GridColDef<Row>[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'subject', headerName: 'Subject', width: 150 },
    { field: 'message', headerName: 'Message', width: 200 },

    {
      field: 'delete',
      headerName: 'Delete',
      width: 100,
      renderCell: params => (
        <IconButton
          onClick={async () => {
            await deleteLeadTemplate({
              variables: {
                id: params.id.toString(),
              },
            });
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const renderError = () => {
    const errorMessage = error ? error.message : mutateError;
    return (
      <Alert severity="error">
        {errorMessage ?? 'Kunde inte ladda bearb. mallar'}
      </Alert>
    );
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>{`${!id ? 'Skapa' : 'Ändra'} bearb. mall`}</DialogTitle>
        <DialogContent>
          <Alert severity="info">
            <strong>Variabler: </strong>
            {`{firstName}, {streetName}, {areaName}, {numberOfRooms}, {homeType}, {senderName}, {senderFirstName}, {agentName}, {agentFirstName}, {followerCount}, {homeMessageCount} {similarHomesFollowerCountInArea} {similarHomesFollowerCountInAssociatedAreas} {sharePMInArea}
           {shareOMInArea} {avgSqmPricePM} {avgSqmPriceOM}`}
          </Alert>
          <TextField
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="subject"
            label="Subject"
            type="text"
            fullWidth
            value={subject}
            onChange={e => setSubject(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="message"
            label="Message"
            type="text"
            fullWidth
            value={message}
            onChange={e => setMessage(e.target.value)}
            multiline
            rows={8}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Ångra
          </Button>
          <Button
            onClick={async () => {
              try {
                if (!id) {
                  await createLeadTemplate({
                    variables: {
                      input: {
                        name,
                        subject,
                        message,
                      },
                    },
                  });
                } else {
                  await updateLeadTemplate({
                    variables: {
                      id: id.toString(),
                      input: {
                        name,
                        subject,
                        message,
                      },
                    },
                  });
                }

                closeDialog();
              } catch (error) {
                if (error instanceof Error) {
                  setMutateError(error.message);
                } else {
                  setMutateError(
                    `Kan inte ${!id ? 'skapa' : 'ändra'} bearb. mall`,
                  );
                }
              }
            }}
            color="primary"
          >
            {!id ? 'Skapa' : 'Ändra'}
          </Button>
        </DialogActions>
      </Dialog>
      <Paper>
        {(error || mutateError) && renderError()}
        <DataGrid
          columns={columns}
          rows={rows}
          onRowClick={({ row }) => {
            // update
            setId(Number(row.id));
            setMutateError('');
            setName(row.name);
            setSubject(row.subject);
            setMessage(row.message);
            setDialogOpen(true);
          }}
        />
      </Paper>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={e => {
          e.preventDefault();
          // create
          setId(undefined);
          setMutateError('');
          setName('');
          setSubject('');
          setMessage('');
          setDialogOpen(true);
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
};

export default LeadTemplates;
