import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { EmailOutlined, PhoneOutlined } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import { Stack } from '@mui/system';
import { sv as locale } from '@norban/locale';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import {
  BofUserAndHomeHeaderFlushVitecDocument,
  BofUserAndHomeHeaderHomeDocument,
  BofUserAndHomeHeaderUserDocument,
  UserBaseFragment,
} from '../generated/backend/graphql';
import usePopupAlert from '../hooks/usePopupAlert';
import { norbanWebLink, vitecEstateLink } from '../utils';

import PageHeader, { PageHeaderButton, PageHeaderLink } from './PageHeader';

type Props = {
  type: 'user' | 'home';
  homeId?: string;
  userId?: string;
  user?: UserBaseFragment;
  onEmailButtonClicked?: () => void;
};

const L = locale.backoffice;

const UserAndHomeHeader = ({
  homeId = undefined,
  userId = undefined,
  user: providedUser = undefined,
  type,
  onEmailButtonClicked = undefined,
}: Props) => {
  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const { data: userData } = useQuery(BofUserAndHomeHeaderUserDocument, {
    variables: {
      userId: userId ?? '',
    },
    skip: !userId,
  });

  const user = providedUser ?? userData?.user;

  const { data: homeData } = useQuery(BofUserAndHomeHeaderHomeDocument, {
    variables: {
      homeId: homeId ?? '',
    },
    skip: !homeId,
  });

  const [flushVitec] = useMutation(BofUserAndHomeHeaderFlushVitecDocument, {
    variables: {
      userId: `${user?.id}`,
    },
  });

  const history = useHistory();

  let headerLinks: PageHeaderLink[] = [];

  // Add links to listing
  if (type === 'home' && homeId) {
    headerLinks = [
      ...headerLinks,
      {
        content: 'annons',
        link: norbanWebLink(homeId),
      },
    ];
  }

  // Add link to vitec
  if (type === 'home' && homeData?.home.vitecEstateId) {
    headerLinks = [
      ...headerLinks,
      {
        content: 'Vitec',
        link: vitecEstateLink(homeData?.home.vitecEstateId),
      },
    ];
  }

  const handleFlushVitec = useCallback(async () => {
    try {
      await flushVitec();
      showPopupAlert(L.userAndHomeHeader.updated, 'success');
    } catch (e) {
      const ae = e as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  }, [flushVitec, showPopupAlert]);

  const headerButtons: PageHeaderButton[] = [
    ...(type === 'home'
      ? [
          {
            disabled: !homeData?.home.vitecEstateId || !user?.id,
            text: 'Uppdatera Vitec',
            icon: <CachedIcon />,
            onClick: handleFlushVitec,
          },
        ]
      : []),
    ...(type === 'user'
      ? [
          {
            text: 'Skicka mejl',
            icon: <EmailIcon />,
            onClick: () => onEmailButtonClicked?.(),
          },
        ]
      : []),
    {
      disabled: !user?.id,
      text: user?.id ? `${user?.id}` : '-',
      icon: <PersonIcon fontSize="small" />,
      onClick: () => {
        history.push(`/users/${user?.id}`);
      },
      variant: 'outlined',
      outline: type === 'user' ? '2px solid #282828' : 'none',
    },
    {
      disabled: !homeId,
      text: homeId ? `${homeId}` : '-',
      icon: <HomeIcon fontSize="small" />,
      onClick: () => {
        history.push(`/homes/${homeId}`);
      },
      variant: 'outlined',
      outline: type === 'home' ? '2px solid #282828' : 'none',
    },
  ];

  let title;

  if (type === 'user') {
    title = (
      <Stack direction="row" alignItems="center" gap={1}>
        <PersonIcon />
        {[user?.name].filter(Boolean).join(', ')}
      </Stack>
    );
  } else {
    title = (
      <Stack direction="row" alignItems="center" gap={1}>
        <HomeIcon />
        {[
          [homeData?.home.address.streetAddress],
          [homeData?.home.address.zipCode, homeData?.home.address.city],
        ]
          .map(a => a.filter(Boolean).join(' '))
          .filter(Boolean)
          .join(', ')}
      </Stack>
    );
  }

  let subtitle;
  if (type === 'user') {
    subtitle = (
      <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
        {user?.email && (
          <Stack direction="row" justifyContent="center" spacing={1}>
            <EmailOutlined />
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </Stack>
        )}
        {user?.phone && (
          <Stack direction="row" justifyContent="center" spacing={1}>
            <PhoneOutlined />
            <a href={`tel:${user.phone}`}>{user.phone}</a>
          </Stack>
        )}
      </Stack>
    );
  }

  return (
    <>
      <PageHeader
        title={title}
        subtitle={subtitle}
        links={headerLinks}
        buttons={headerButtons}
      />
      <PopupAlert />
    </>
  );
};

export default UserAndHomeHeader;
